import Vue from 'vue'
import VueRouter from 'vue-router'
import goTo from 'vuetify/lib/services/goto'
import FooterComponent from '@/layouts/components/FooterComponent.vue'
import store from '../store/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'home',
  },
  {
    path: '/dashboard',
    redirect: 'login',
  },
  {
    path: '/account',
    redirect: 'login',
  },
  {
    path: '/home',
    name: 'home',
    components: {
      default: () => import('@/layouts/Home.vue'),
      footer: FooterComponent
    },
    meta: {
      layout: 'blank',
    },
  },

  {
    path: '/ressources',
    name: 'ressources',
    components: {
      default: () => import('@/views/pages/Ressources'),
      //footer: FooterComponent
    },
    // meta: {
    //   layout: 'blank',
    // },
  },

  {
    // path: '/dashc/:company/home',
    path: '/dashc/:company/home',
    name: 'company.home',
    // component: () => import('@/views/dashboard/company/HomeCompany.vue'),
    component: () => import('@/views/dashboard/company/Account.vue'),
    meta: {
      needsAuthen: true
    }
  },
  {
    // path: '/dashc/:company/account',
    path: '/dashc/:company/account',
    // name: 'company.account',
    component: () => import('@/views/dashboard/company/Account.vue'),
    props: true,
    children: [
      {
        path: '',
        name: 'company.account',
        redirect: { name: 'company.account.profile' }
      },
      {
        path: 'profile',
        name: 'company.account.profile',
        component: () => import('@/views/dashboard/company/account/AccountInfos.vue')
      },
      {
        path: 'setting',
        name: 'company.account.setting',
        component: () => import('@/views/dashboard/company/account/AccountSettings.vue'),
      },
    ],
    meta: {
      needsAuthen: true
    }
  },


  // {
  //   path: '/dashc/:company/view/',
  //   name: 'company.view',
  //   component: () => import('@/views/dashboard/company/Accoutn.vue'),
  //   meta: {
  //     layout: 'blank',
  //   },
  // },

  {
    path: '/dashc/:company/account/settings',
    name: 'company.setting',
    component: () => import('@/views/dashboard/company/Account.vue'),
    meta: {
      needsAuthen: true
    }
  },

  ////////////////////////////////////////////////////////////////////////////////////
  /**************************           User account           **********************/
  ////////////////////////////////////////////////////////////////////////////////////
  {
    path: '/dashu/home',
    name: 'user.home',
    // component: () => import('@/views/dashboard/professional/UserHome.vue'),
    component: () => import('@/views/dashboard/professional/Account.vue'),
    meta: {
      needsAuthen: true
    }
  },

  /************************** Events       **********************/
  {
    path: "/dashu/event",
    name: 'user.event',
    component: () => import('@/views/dashboard/events/UserEvent.vue'),
    props: true,
    meta: {
      // layout: 'blank',
      needsAuthen: true,
      // needsSubscription: true
    },
  },

  /************************** Blog       **********************/
  {
    path: "/dashu/blog",
    name: 'user.blog',
    component: () => import('@/views/dashboard/blog/UserBlog.vue'),
    props: true,
    meta: {
      // layout: 'blank',
      needsAuthen: true,
      // needsSubscription: true
    },
  },

  {
    path: '/dashu/account',
    name: 'user.account',
    component: () => import('@/views/dashboard/professional/Account.vue'),
    props: true,
    children: [
      // {
      //   path: '',
      //   //name: 'user.account',
      //   redirect: { name: 'user.account.profile' }
      // },
      {
        path: 'profile',
        name: 'user.account.profile',
        component: () => import('@/views/dashboard/professional/account/AccountInfos.vue')
      },
      {
        path: 'setting',
        name: 'user.account.setting',
        component: () => import('@/views/dashboard/professional/account/AccountSettings.vue'),
      },
      // {
      //   path: 'changepass',
      //   name: 'user.account.pass',
      //   component: () => import('@/views/dashboard/professional/account/AccountSecurity.vue'),
      // },
    ],

    meta: {
      needsAuthen: true
    }
  },

  {
    path: '/dashu/new-company',
    name: 'user.create.company',
    component: () => import('@/views/dashboard/company/NewCompany.vue'),
    meta: {
      needsAuthen: true
    }
  },

  {
    path: '/dashu/my-companies',
    name: 'user.list.company',
    component: () => import('@/views/dashboard/company/UserCompany.vue'),
    meta: {
      needsAuthen: true
    }
  },

  {
    path: '/dashu/networking/views',
    name: 'user.join.list',
    component: () => import('@/views/dashboard/networking/NetworkingHome.vue'),
    meta: {
      needsAuthen: true
    }
  },

  {
    path: '/dashu/vacancies/views',
    name: 'user.vacancies.list',
    component: () => import('@/views/dashboard/professional/Vacancies.vue'),
    meta: {
      needsAuthen: true
    }
  },

  {
    path: "/dashu/vacancies/views:offer_id-:offer_title",
    name: 'user.vacancies.details',
    component: () => import('@/views/dashboard/professional/vacancies/VacanciesDetails'),
    props: true,
    meta: {
      // layout: 'blank',
      needsAuthen: true
    },
  },
  {
    path: "/dashu/vacancies/details/:keyword/:offer_title",
    name: 'user.vacancies.external.details',
    component: () => import('@/views/dashboard/professional/vacancies/ExternalVacancies'),
    props: true,
    meta: {
      // layout: 'blank',
      needsAuthen: true,
      // needsSubscription: true
    },
  },

  {
    // path: '/dashu/request/views/:ids(\\d+)/rfp:f_id',
    path: '/dashu/request/views/:f_id',
    name: 'u.request.detail',
    component: () => import('@/views/dashboard/requests/RequestDetails.vue'),
    meta: {
      needsAuthen: true
    }
  },
  {
    // path: '/dashc/:company/request/views/:ids(\\d+)/:f_id',
    path: '/dashc/:company/request/views/:f_id',
    name: 'c.request.detail',
    component: () => import('@/views/dashboard/requests/RequestDetails.vue'),
    meta: {
      needsAuthen: true
    }
  },

  
    /************************** Events       **********************/
    {
      path: "/dashc/event",
      name: 'company.event',
      component: () => import('@/views/dashboard/events/CompanyEvent.vue'),
      props: true,
      meta: {
        // layout: 'blank',
        needsAuthen: true,
        // needsSubscription: true
      },
    },
  
    /************************** Blog       **********************/
    {
      path: "/dashc/blog",
      name: 'company.blog',
      component: () => import('@/views/dashboard/blog/CompanyBlog.vue'),
      props: true,
      meta: {
        // layout: 'blank',
        needsAuthen: true,
        // needsSubscription: true
      },
    },

  ////////////////////////////////////////////////////////////////////////////////////
  /************************** company services                 **********************/
  ////////////////////////////////////////////////////////////////////////////////////
  {
    path: "/dashc/:company/services/view/:f_id/:title",
    name: 'company.service.view',
    component: () => import('@/views/dashboard/services/ViewServices.vue'),
    meta: {
      needsAuthen: true
    }
  },
  {
    path: "/dashc/:company/services/",
    name: 'company.service',
    component: () => import('@/views/dashboard/services/Services.vue'),
    props: true,
    children: [
      {
        path: 'list',
        name: 'company.service.list',
        component: () => import('@/views/dashboard/services/ServiceDataTable.vue')
      },
      {
        path: 'new',
        name: 'company.service.new',
        component: () => import('@/views/dashboard/services/NewServices.vue'),
      },
    ],
    meta: {
      needsAuthen: true
    }
  },
  {
    path: `/dashc/:company/services/edit/:value/:title`,
    name: 'company.service.edit',
    component: () => import('@/views/dashboard/services/NewServices.vue'),
    meta: {
      needsAuthen: true
    }
  },
  ////////////////////////////////////////////////////////////////////////////////////
  /************************** company request for proposal **************************/
  ////////////////////////////////////////////////////////////////////////////////////
  {
    path: "/dashc/:company/request/rfp/",
    //name: 'company.rfp',
    component: () => import('@/views/dashboard/requests/RFP.vue'),
    props: true,
    children: [
      {
        path: '',
        name: 'company.rfp',
        redirect: { name: 'company.rfp.inbox' }
      },
      {
        path: 'inbox',
        name: 'company.rfp.inbox',
        component: () => import('@/views/dashboard/requests/rfp/Inbox.vue')
      },
      {
        path: 'outbox',
        name: 'company.rfp.outbox',
        component: () => import('@/views/dashboard/requests/rfp/Outbox.vue')
      },
      {
        path: 'new',
        name: 'company.rfp.new',
        component: () => import('@/views/dashboard/requests/rfp/NewRFP.vue')
      },
      {
        path: `edit/:value/:title`,
        name: 'company.rfp.edit',
        component: () => import('@/views/dashboard/requests/rfp/NewRFP.vue'),
      },
    ],
    meta: {
      needsAuthen: true
    }
  },

  ////////////////////////////////////////////////////////////////////////////////////
  /************************** company proposal **************************/
  ////////////////////////////////////////////////////////////////////////////////////
  {
    path: "/dashc/:company/vacancies/",
    name: 'company.vacancies',
    component: () => import('@/views/dashboard/vacancies/ListJob.vue'),
    meta: {
      needsAuthen: true
    }
  },
  {
    path: '/dashc/:company/vacancies/new',
    name: 'company.vacancies.create',
    component: () => import('@/views/dashboard/vacancies/NewJob.vue'),
    meta: {
      needsAuthen: true
    }
  },
  {
    path: '/dashc/:company/vacancies/edit/:jobid/:title',
    name: 'company.vacancies.edit',
    component: () => import('@/views/dashboard/vacancies/NewJob.vue'),
    meta: {
      needsAuthen: true
    }
  },
  
  {
    path: '/dashc/:company/vacancies/details/:jobid/:title',
    name: 'company.vacancies.details',
    component: () => import('@/views/dashboard/vacancies/components/ViewVacanciesDescription.vue'),
    meta: {
      needsAuthen: true
    }
  },

  {
    path: '/dashc/:company/vacancies/feedback',
    name: 'company.vacancies.feedback',
    component: () => import('@/views/dashboard/vacancies/JobReplying.vue'),
    meta: {
      needsAuthen: true
    }
  },

  ////////////////////////////////////////////////////////////////////////////////////
  /************************** company request for colaboration **********************/
  ////////////////////////////////////////////////////////////////////////////////////
  {
    path: "/dashc/:company/request/rfc/",
    // name: 'company.rfc',
    component: () => import('@/views/dashboard/requests/RFC.vue'),
    props: true,
    children: [
      {
        path: '',
        name: 'company.rfc',
        redirect: { name: 'company.rfc.inbox' }
      },
      {
        path: 'inbox',
        name: 'company.rfc.inbox',
        component: () => import('@/views/dashboard/requests/rfc/Inbox.vue')
      },
      {
        path: 'outbox',
        name: 'company.rfc.outbox',
        component: () => import('@/views/dashboard/requests/rfc/Outbox.vue')
      },
      {
        path: 'new',
        name: 'company.rfc.new',
        component: () => import('@/views/dashboard/requests/rfc/NewRFC.vue')
      },
    ],
    meta: {
      needsAuthen: true
    }
  },

  ////////////////////////////////////////////////////////////////////////////////////
  /************************** professionnal request for colaboration ****************/
  ////////////////////////////////////////////////////////////////////////////////////
  {
    path: "/dashu/request/rfc/",
    // name: 'user.rfc',
    redirect: '/dashu/request/rfc/inbox',
    component: () => import('@/views/dashboard/requests/RFC.vue'),
    props: true,
    children: [
      {
        path: '',
        name: 'user.rfc',
        redirect: { name: 'user.rfc.inbox' }
      },
      {
        path: 'inbox',
        name: 'user.rfc.inbox',
        component: () => import('@/views/dashboard/requests/rfc/Inbox.vue')
      },
      {
        path: 'outbox',
        name: 'user.rfc.outbox',
        component: () => import('@/views/dashboard/requests/rfc/Outbox.vue')
      },
      {
        path: 'new',
        name: 'user.rfc.new',
        component: () => import('@/views/dashboard/requests/rfc/NewRFC.vue')
      },
    ],
    meta: {
      needsAuthen: true
    }
  },
  /**
   * Proposals details pages
   */

  {
    path: "/offer/proposal/",
    name: 'proposal.list',
    component: () => import('@/views/pages/ProposalsPage.vue'),
    props: true,
    meta: {
      layout: 'blank',
      // needsAuthen: true,
      // needsSubscription: true
    },
  },

  {
    path: "/offer/proposal/details/:offer_title:offer_id",
    name: 'proposal.details',
    component: () => import('@/views/pages/proposals/ProposalDetails.vue'),
    props: true,
    meta: {
      layout: 'blank',
      needsAuthen: true,
      // needsSubscription: true
    },
  },
  {
    path: "/offer/proposal/details/:keyword/:offer_title",
    name: 'proposal.external.details',
    component: () => import('@/views/pages/proposals/ExternalProposals.vue'),
    props: true,
    meta: {
      layout: 'blank',
      needsAuthen: true,
      // needsSubscription: true
    },
  },

  {
    path: "/offer/proposal/reply/:offer_title/:offer_id",
    name: 'proposal.reply',
    component: () => import('@/views/pages/proposals/ReplyProposal.vue'),
    props: true,
    meta: {
      layout: 'blank',
      needsAuthen: true,
      // needsSubscription: true
    },
  },

  /**
   * Jobs offers
   */
  {
    path: "/offer/vacancies",
    name: 'vacancies.list',
    component: () => import('@/views/pages/VacanciesPage.vue'),
    props: true,
    meta: {
      layout: 'blank',
      // needsAuthen: true
    },
  },

  {
    path: "/offer/vacancies/:offer_id-:offer_title",
    name: 'vacancies.details',
    component: () => import('@/views/pages/vacancies/VacanciesDetails'),
    props: true,
    meta: {
      // layout: 'blank',
      needsAuthen: true
    },
  },
  {
    path: "/offer/vacancies/details/:keyword/:offer_title",
    name: 'vacancies.external.details',
    component: () => import('@/views/pages/vacancies/ExternalVacancies.vue'),
    props: true,
    meta: {
      // layout: 'blank',
      needsAuthen: true,
      // needsSubscription: true
    },
  },

  {
    path: "/offer/vacancies/reply/:offer_title/:offer_id",
    name: 'vacancies.reply',
    component: () => import('@/views/pages/vacancies/ReplyVacancie.vue'),
    props: true,
    meta: {
      layout: 'blank',
      needsAuthen: true,
      // needsSubscription: true
    },
  },

  {
    path: '/subscription/purchase/:payement',
    name: 'subscription.purchase',
    component: () => import('@/views/pages/pricing/SubscriptionPage.vue'),
    meta: {
      layout: 'blank',
      needsAuthen: true,
      needsInitSubscription: true
    },
  },

  ///////////////////////////////////////////////////////////////////////////////////

  //  Defined routes of pages
  {
    path: '/company',
    name: 'company',
    component: () => import('@/views/pages/CompanyPage.vue'),
    meta: {
      layout: 'blank',
    },
  },

  // View single company details
  {
    path: '/company/views/:ids(\\d+)/:f_id',
    name: 'company.views',
    component: () => import('@/views/pages/company/CompanyDetails.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/professional',
    name: 'professional',
    component: () => import('@/views/pages/Professional.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/professional/views/:ids(\\d+)/:f_id',
    name: 'user.views',
    component: () => import('@/views/pages/users/UserDetails.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/account/login',
    name: 'login',
    component: () => import('@/views/pages/Login.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/account/login/forgot-password',
    name: 'forgotpass',
    component: () => import('@/views/pages/login/ForgotPassword.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/account/register/activate-account',
    name: 'activateaccount',
    component: () => import('@/views/pages/login/ActivateAccount.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/account/register',
    name: 'register',
    component: () => import('@/views/pages/Register.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('@/views/pages/Contact.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/vacancies/view/:jobkeys',
    name: 'view.vacancies',
    component: () => import('@/views/pages/Contact.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/pages/AboutUs.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/pricing',
    name: 'pricing',
    component: () => import('@/views/pages/Pricing.vue'),
    meta: {
      layout: 'blank',
    },
  },

  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0

    if (to.hash) {
      scrollTo = to.hash
    } else if (savedPosition) {
      scrollTo = savedPosition.y
    }

    return goTo(scrollTo)
  },
})

router.beforeEach((to, from, next) => {
  if (to.meta.needsInitSubscription) {
    if (store.getters.getInitSubscription.init === true) {
      next()
    }
    else {
      next("/pricing")
    }
  } else {
    next()
  }
  if (to.meta.needsAuthen) {
    if (store.getters.isLoggedIn === true) {
      next()
    }
    else {
      next("/account/login")
    }
    // next()
  } else {
    next()
  }

  if (to.meta.needsSubscription) {
    if (store.getters.isSubscribed === true) {
      next()
    }
    else {
      next("/pricing")
    }
  } else {
    next()
  }
})
export default router
