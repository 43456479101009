<template>
  <!-- REMOVE THIS PAGE ..................... -->

  <div class="">
    <!-- Page heading -->
    <header class="bg-gray-50 py-8">
      <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 xl:flex xl:items-center xl:justify-between">
        <div class="min-w-0 flex-1">
          <nav class="flex" aria-label="Breadcrumb">
            <ol role="list" class="flex items-center space-x-4">
              <li>
                <div>
                  <a href="#" class="text-sm font-medium text-gray-500 hover:text-gray-700">Home</a>
                </div>
              </li>
              <li>
                <div class="flex items-center">
                </div>
              </li>
            </ol>
          </nav></div>
      </div>
    </header>
    <div>
      <!--  -->
      <div v-if="loader.datarfc === false">
        <!-- <h1 class="sr-only">Recent questions</h1> -->
        <ul role="list" class="space-y-4 bg-white mt-4" v-for="(valuerfc, i) in datarfc.data" :key="i">
          <li class="py-6 sm:rounded-lg sm:p-6">
            <article aria-labelledby="question-title-81614">
              <div>
                <div class="flex space-x-3">
                  <div class="flex-shrink-0">
                    <!-- <img class="h-10 w-10 rounded-full"
                      src="https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=2&amp;w=256&amp;h=256&amp;q=80"
                      alt=""> -->
                  </div>
                  <div class="min-w-0 flex-1">
                    <p class="text-sm font-medium text-gray-900">
                      <a href="#" class="hover:underline">{{ valuerfc.enterprise ? valuerfc.user.username :
                        valuerfc.enterprise.name
                        }}</a>
                    </p>
                    <p class="text-sm text-gray-500">
                      <a href="#" class="hover:underline">
                        <time datetime="2020-12-09T11:43:00">{{ valuerfc.sent_at }}</time>
                      </a>
                    </p>
                  </div>
                </div>
                <h2 id="question-title-81614" class="mt-4 text-base font-medium text-gray-900">{{ valuerfc.object }}
                </h2>
              </div>
              <div class="mt-2 space-y-4 text-sm text-gray-700">
                <p v-html="valuerfc.content"></p>
              </div>
              <!-- <div class="mt-6 flex justify-between space-x-8">
                <div class="flex space-x-6">
                  <span class="inline-flex items-center text-sm">
                    <button type="button" :class="likeBtnStatus + ' filter inline-flex space-x-2'"
                      @click="onPressLikeBtn()">
                      <svg class="h-5 w-5" x-description="Heroicon name: mini/hand-thumb-up"
                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path
                          d="M1 8.25a1.25 1.25 0 112.5 0v7.5a1.25 1.25 0 11-2.5 0v-7.5zM11 3V1.7c0-.268.14-.526.395-.607A2 2 0 0114 3c0 .995-.182 1.948-.514 2.826-.204.54.166 1.174.744 1.174h2.52c1.243 0 2.261 1.01 2.146 2.247a23.864 23.864 0 01-1.341 5.974C17.153 16.323 16.072 17 14.9 17h-3.192a3 3 0 01-1.341-.317l-2.734-1.366A3 3 0 006.292 15H5V8h.963c.685 0 1.258-.483 1.612-1.068a4.011 4.011 0 012.166-1.73c.432-.143.853-.386 1.011-.814.16-.432.248-.9.248-1.388z">
                        </path>
                      </svg>
                      <span class="font-medium">29</span>
                      <span class="sr-only">likes</span>
                    </button>
                  </span>
                  <span class="inline-flex items-center text-sm">
                    <button type="button" class="inline-flex space-x-2 text-gray-400 hover:text-gray-500">
                      <svg class="h-5 w-5" x-description="Heroicon name: mini/chat-bubble-left-ellipsis"
                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd"
                          d="M10 2c-2.236 0-4.43.18-6.57.524C1.993 2.755 1 4.014 1 5.426v5.148c0 1.413.993 2.67 2.43 2.902.848.137 1.705.248 2.57.331v3.443a.75.75 0 001.28.53l3.58-3.579a.78.78 0 01.527-.224 41.202 41.202 0 005.183-.5c1.437-.232 2.43-1.49 2.43-2.903V5.426c0-1.413-.993-2.67-2.43-2.902A41.289 41.289 0 0010 2zm0 7a1 1 0 100-2 1 1 0 000 2zM8 8a1 1 0 11-2 0 1 1 0 012 0zm5 1a1 1 0 100-2 1 1 0 000 2z"
                          clip-rule="evenodd"></path>
                      </svg>
                      <span class="font-medium text-gray-900">11</span>
                      <span class="sr-only">replies</span>
                    </button>
                  </span>
                  <span class="inline-flex items-center text-sm">
                    <button type="button" class="inline-flex space-x-2 text-gray-400 hover:text-gray-500">
                      <svg class="h-5 w-5" x-description="Heroicon name: mini/eye" xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path d="M10 12.5a2.5 2.5 0 100-5 2.5 2.5 0 000 5z"></path>
                        <path fill-rule="evenodd"
                          d="M.664 10.59a1.651 1.651 0 010-1.186A10.004 10.004 0 0110 3c4.257 0 7.893 2.66 9.336 6.41.147.381.146.804 0 1.186A10.004 10.004 0 0110 17c-4.257 0-7.893-2.66-9.336-6.41zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                          clip-rule="evenodd"></path>
                      </svg>
                      <span class="font-medium text-gray-900">2.7k</span>
                      <span class="sr-only">views</span>
                    </button>
                  </span>
                </div>
                <div class="flex text-sm">
                  <span class="inline-flex items-center text-sm">
                    <button type="button" class="inline-flex space-x-2 text-gray-400 hover:text-gray-500">
                      <svg class="h-5 w-5" x-description="Heroicon name: mini/share" xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path
                          d="M13 4.5a2.5 2.5 0 11.702 1.737L6.97 9.604a2.518 2.518 0 010 .792l6.733 3.367a2.5 2.5 0 11-.671 1.341l-6.733-3.367a2.5 2.5 0 110-3.475l6.733-3.366A2.52 2.52 0 0113 4.5z">
                        </path>
                      </svg>
                      <span class="font-medium text-gray-900">Share</span>
                    </button>
                  </span>
                </div>
              </div> -->
            </article>
          </li>
        </ul>
      </div>
      <div class="flex justify-center items-center h-screen" v-else>
        <div class="rounded-full h-20 w-20 secondary animate-ping"></div>
      </div>
      <!--  -->
    </div>

  </div>
</template>

<script>
import { mdiAccountNetwork, mdiApproximatelyEqual } from '@mdi/js';
import UserCompany from '@/views/dashboard/company/UserCompany.vue';

import PricingResumeCard from '../components/PricingResumeCard.vue';
import CardStatisticRegister from '../components/CardStatisticRegister.vue';
import CardStatisticCount from '../components/CardStatisticCount.vue';

import CardDashItem from '../components/CardDashItem.vue';
import { Drequest } from '@/plugins/Drequest'

// import { GChart } from 'vue-google-charts'
import { GChart } from 'vue-google-charts/legacy'

import CardLinearChart from '@/views/dashboard/components/CardLinearChart.vue'

export default {
  components: {
    PricingResumeCard,
    CardStatisticRegister,
    CardStatisticCount,
    UserCompany,
    CardDashItem,
    CardLinearChart,

    // For graphics
    GChart,
  },
  data() {
    return {
      openTab: 1,
      likeBtn: false,
      links: [
        {
          id: '1',
          title: 'Colaborations',
          description: '',
          color: 'secondary'
        },
        {
          id: '2',
          title: 'Proposal',
          description: '',
          color: 'teal'
        },
        {
          id: '3',
          title: 'Vacancies',
          description: '',
          color: 'teal'
        }, {
          id: '4',
          title: 'Network with people',
          description: '',
          color: 'teal'
        },
      ],
      show: false,
      total_company: 0,
      networkdata: {
        title: 'Networking',
        sent: 0,
        received: 0,
        confirm: 0
      },

      rfcdata: {
        title: 'Request for colaboration',
        sent: 0,
        received: 0,
        confirm: 0
      },

      dashservices: [
        {
          title: 'Network',
          subtitle: 'View your network sumary',
          link: 'user.join.list',
          icon: this.icons.mdiAccountNetwork,
          items: []
        },
        {
          title: 'Requests for colaborations',
          subtitle: 'View your rfc sumary',
          link: 'user.rfc',
          icon: this.icons.mdiApproximatelyEqual,
          items: []
        },
      ],

      datarfc: {
        'nb_element': 0,
        'data': []
      },
      loader: {
        datarfc: true
      }
    }
  },
  computed: {
    likeBtnStatus() {
      return this.likeBtn ? 'text-green-600' : 'text-gray-400'
    },
  },
  methods: {
    onPressLikeBtn() {
      this.likeBtn = !this.likeBtn
    },

    toggleTabs: function (tabNumber) {
      this.openTab = tabNumber
    }
  },
  beforeMount() {
    Drequest.api(`lazyloading.user?dfilters=on&id:eq=${this.$store.getters.getUser.id}`).get((response) => {
      if (response.success === true) {
        const user = response.listEntity[0]
        this.$store.commit("setUser", {
          id: user.id,
          email: user.email,
          firstname: user.firstname,
          lastname: user.lastname,
          phonenumber: user.phonenumber,
          username: user.username,
          isactivated: user.is_activated,
          adress: user.adress,
          country: user.country,
          // api_key: user.api_key,
          lastlogin: user.last_login,
          logo: user.logo,
          about: user.about,
          lang: user.lang,
          username: user.username,
        });
        this.$store.commit("setToken", user.activationcode);
        this.$store.commit("setApiToken", user.api_key);
      }
      else { }
    }).catch((err) => { });

    Drequest.api(`lazyloading.enterprise?dfilters=on&user_id:eq=${this.$store.getters.getUser.id}`).get((response) => {
      if (response.success === true) {
        this.total_company = response.nb_element
      }
      else { }
    }).catch((err) => { });

    Drequest.api(`lazyloading.colaborator?dfilters=on&user_id:eq=${this.$store.getters.getUser.id}`).get((response) => {
      if (response.success === true) {
        this.networkdata.sent = response.nb_element
        // this.dashservices[0].items.push({ title: 'Sent', value: response.nb_element })
      }
      else { this.dashservices[0].items.push({ title: 'Sent', value: 0 }) }
    }).catch((err) => { });

    Drequest.api(`lazyloading.colaborator?dfilters=on&recipientuser:eq=${this.$store.getters.getUser.id}`).get((response) => {
      if (response.success === true) {
        this.networkdata.received = response.nb_element
        // this.dashservices[0].items.push({ title: 'Received', value: response.nb_element }) 
      }
      else { this.dashservices[0].items.push({ title: 'Received', value: 0 }) }
    }).catch((err) => { });

    Drequest.api(`lazyloading.colaborator?dfilters=on&status:eq=2&user_id:eq=${this.$store.getters.getUser.id}&recipientuser:oreq=${this.$store.getters.getUser.id}`).get((response) => {
      if (response.success === true) {
        this.networkdata.confirm = response.nb_element
        // this.dashservices[0].items.push({ title: 'Colaborators', value: response.nb_element })
      }
      else { this.dashservices[0].items.push({ title: 'Colaborators', value: 0 }) }
    }).catch((err) => { });

    Drequest.api(`lazyloading.rfcuser?dfilters=on&rfc.user_id:eq=${this.$store.getters.getUser.id}`).get((response) => {
      if (response.success === true) {
        this.rfcdata.sent = response.nb_element

        // console.log(response.listEntity)
        // this.dashservices[1].items.push({ title: 'Sent', value: response.nb_element }) 
      }
      else { this.dashservices[1].items.push({ title: 'Sent', value: 0 }) }
    }).catch((err) => { });

    Drequest.api(`lazyloading.rfc?dfilters=on&user_id:neq=${this.$store.getters.getUser.id}`).get((response) => {
      this.loader.datarfc = true
      if (response.success === true) {
        this.rfcdata.received = response.nb_element
        this.datarfc.nb_element = response.nb_element
        this.datarfc.data = response.listEntity
        // this.dashservices[1].items.push({ title: 'Received', value: response.nb_element }) 
        this.loader.datarfc = false
      }
      else {
        this.dashservices[1].items.push({ title: 'Received', value: 0 })
        this.loader.datarfc = true
      }
    }).catch((err) => { this.loader.datarfc = true });

    Drequest.api(`lazyloading.rfcuser?dfilters=on&status:eq=2&rfc.user_id:eq=${this.$store.getters.getUser.id}`).get((response) => {
      if (response.success === true) {
        this.rfcdata.confirm = response.nb_element
        // this.dashservices[1].items.push({ title: 'Confirmed', value: response.nb_element }) 
      }
      else {
        // this.dashservices[1].items.push({ title: 'Confirmed', value: 0 }) 
      }
    }).catch((err) => { });
  },
  setup(props) {
    const icons = { mdiAccountNetwork, mdiApproximatelyEqual }

    return {
      icons
    }
  }
}
</script>


<style lang="scss">
//@import '@/styles/styles.scss';
@import '~@/styles/main-dash-style.scss';
@import '~@/styles/utils-style.scss';

.is-red {
  background: red !important;
}

.is-blue {
  background: blue !important;
}

.outine-2 {
  border: 1px solid white;
}

.rounf {
  border: solid 1px #000 !important;
}

.learn-more-btn {
  text-transform: initial;
}
</style>
